@import "_sass/common";



/******* GLOBAL VIDEO *******/

#globalVideo {
	display: none;
}

body.video #globalVideo {
	display: block;
}



/******* PARTNER BENEFITS *******/

#partnerBenefits-userBase .counter li .wrapper {
	@include transform (translateY(0));
	@include animation (none);
}